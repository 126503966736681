/* Container styling */
.listening-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    position: relative;
}

/* Text under animation */
.listening-text.desktop {
    position: absolute;
    top: calc(30% + 5px);
    /* Adjust this based on the avatar size */
    left: 70%;
    transform: translate(-40%, -80%);
    font-size: .5em;
    color: #6c6c6c;
}

.listening-text.mobile {
    position: absolute;
    top: calc(25% + 10px);
    /* Adjust this based on the avatar size */
    left: 75%;
    transform: translate(-80%, -50%);
    font-size: 1em;
    color: #6c6c6c;
}

/* Circles animation */
.circle-container.desktop {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    transform: translate(-20%, -45%);
    z-index: 1;
}

.circle-container.mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-100%, -50%);
    z-index: 1;
}

.circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid rgba(0, 123, 255, 0.5);
    border-radius: 50%;
    animation: pulse 2s infinite;
    z-index: 1;
}

.circle.delay-1 {
    animation-delay: 0.5s;
}

.circle.delay-2 {
    animation-delay: 1s;
}

/* Keyframes for pulse effect */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}