.businessProfile {
  width: 90%;
  margin: auto;
}

#menu-serviceAreas {

  .Mui-selected {
    background: gray
  }
}

//My Profile Page Styles

.my-profile-container {
  .my-profile-menu {
    border-radius: 6px;
    cursor: pointer;
  }

  .my-profile-menu-icon {
    font-size: 1.4rem;
  }

  .my-profile-menu:hover {
    background: "lightgray";
    border-radius: 6px
  }

  .myProf-active-menu {
    background: #aac1ed;
  }

  .myProf-inactive-menu {}
}

// Profile Image Styles 

.profile-avatar {
  position: relative;
  width: 120px;
  height: 120px;

  &__container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 50%;

    // Camera icon styles
    svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  &:hover {
    .profile-avatar__overlay {
      opacity: 1;
    }
  }
}

// Optional: Responsive sizes
$avatar-sizes: (
  'sm': 32px,
  'md': 120px,
  'lg': 48px,
  'xl': 64px
);

@each $size, $value in $avatar-sizes {
  .profile-avatar--#{$size} {
    width: $value;
    height: $value;
  }
}